<template>
  <div v-if="!!geo_area && !!geo_area.areas" class="bg-block checkbox-block">
    <div class="bg-block-h">
      <h3 :title="area_title">{{ charLimit(area_title, 22) }}</h3>
      <div class="toggle"></div>
    </div>
    <span v-for="(option, ix) in geo_area.areas" :key="ix">
      <b-form-checkbox
        v-model="geo_area.areas[ix].selected"
        :name="`cbx${ix}`"
        class="geoCB"
        @change="handleCheckboxChange(ix, $event)"
        >{{ option.text }}
        <span class="office" v-if="option.value !== '00000000-0000-0000-0000-000000000000' && geo_type === 'individual'"><br>[{{ option.office }}]</span>
      </b-form-checkbox>
    </span>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'geographic-areas',
  props: {
    area_prop: {
      type: Object,
      default: null,
    },
    geo_type: {
      type: String,
    },
    title_override: {
      type: String
    }
  },
  data() {
    return {
      area_title: null,
      geo_area: null,
    }
  },
  methods: {
    ...mapActions({
      markCommitteesAsSelected: 'announcements/markCommitteesAsSelected',
      setAllCampLeaders: 'announcements/setAllCampLeaders',
      setAllStateLeaders: 'announcements/setAllStateLeaders',
      setCountries: 'announcements/setCountries',
      setIndividuals: 'announcements/setIndividuals',
      setCampAuxiliaryLeaders: 'announcements/setCampAuxiliaryLeaders',
      setCampGideonLeaders: 'announcements/setCampGideonLeaders',
      setStateAuxiliaryLeaders: 'announcements/setStateAuxiliaryLeaders',
      setStateGideonLeaders: 'announcements/setStateGideonLeaders',
      setStateRegionArea: 'announcements/setStateRegionArea',
      setUserCommittees: 'announcements/setUserCommittees'
    }),
    charLimit(text, limit) {
      if (!!text) {
        return text.length > limit ? text.substring(0, limit) + '...' : text
      }
    },
    async handleCheckboxChange(item, event) {
      let on_count = 0
      this.geo_area.areas.map((ox, ax) => {
        if (ox.selected && ax > 0) {
          on_count++
        }
      })
      switch (item) {
        case 0:
          await (async () => {
            this.geo_area.areas.map((ar, ax) => {
              ar.selected = on_count === this.geo_area.areas.length - 1 ? ax < 0 : ax > 0
            })
          })()
          break
        default:
          await (async () => {
            this.geo_area.areas[0].selected = false
          })()
          break
      }
      if (this.geo_type.includes('region')) {
        await this.setStateRegionArea(this.geo_area)
      } else if (this.geo_type.includes('committee')) {
        await this.markCommitteesAsSelected(this.geo_area)
      } else if (this.geo_type.includes('individual')) {
        await this.setIndividuals(this.geo_area)
      } else if (this.geo_type.includes('countries')) {
        await this.setCountries(this.geo_area)
      } else if (this.geo_type.includes('rcMembers')) {
        await this.setUserCommittees(this.geo_area)
      }
      this.$emit('checkboxChanged', event)
    },
    setTitle() {
      if (!!this.title_override) {
        this.area_title = this.title_override;
      }
      else {
        this.area_title = this.area_prop.title;
      }
    }
  },
  computed: {
    ...mapGetters({}),
  },
  async created() {
    this.geo_area = this.area_prop
    if (this.geo_type.includes('individual')) {
      await this.setIndividuals(this.geo_area)
    }
    this.setTitle();
    // if (
    //   this.geo_type.includes('countries') &&
    //   this.committeeCountries.length > 0
    // ) {
    //   await this.setCountries(this.geo_area)
    // }
    // },
    // beforeDestroy() {
    //   this.geo_area.areas.map((am, gx) => (am.selected = gx === 100))
  },
  watch: {
    area_prop: {
      deep: true,
      handler() {
        this.setTitle()
      }
    },
    title_override: {
      deep: true,
      handler() {
        this.setTitle()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  .office {
    font-size: 70%;
    line-height: 20%;
  }
  .geoCB {
    min-width: 150px;
    margin-bottom: 15px;
  }

</style>
